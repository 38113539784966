import React from "react";
import "./App.scss";

import Carousel from "react-material-ui-carousel";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import {
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
} from "@material-ui/core";

const sampleText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          dapibus imperdiet ultrices. Morbi quis dolor pharetra, blandit nulla
          sit amet, semper sem. Sed tempor auctor nibh ac dignissim. Fusce eu
          sagittis tortor, sit amet tincidunt urna. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Maecenas pellentesque gravida semper.
          Aenean vehicula erat ut odio tincidunt mattis. Nunc et efficitur ante.`;

function App() {
  return (
    <Router>
      <div className="app">
        <div className="top-nav">
          <div className="logo-container">
            <Link to="/">
              <img src={"./img/logo.png"} alt="Feral Fantasy" id="logo" />
            </Link>
          </div>
          <div className="nav-container">
            <nav className="restrict-width">
              <NavLink to="/store" exact activeClassName="active">
                Store
              </NavLink>
              <NavLink to="/commissions" exact activeClassName="active">
                Commissions
              </NavLink>
              <NavLink to="/gallery" exact activeClassName="active">
                Gallery
              </NavLink>
            </nav>
          </div>
        </div>
        <div className="page">
          <Switch>
            <Route path="/store">
              <Store></Store>
            </Route>
            <Route path="/commissions">
              <Commissions></Commissions>
            </Route>
            <Route path="/gallery">
              <Gallery></Gallery>
            </Route>
            <Route path="/">
              <Home></Home>
            </Route>
          </Switch>
        </div>
        <div className="footer">
          <div className="footer-container restrict-width">
            <div className="social-media-links">
              <a
                href="https://twitter.com/QuilynDragon?ref_src=twsrc%5Etfw"
                className="social-media-icon"
              >
                <TwitterIcon></TwitterIcon>
              </a>
              <a
                href="https://picarto.tv/FeralFantasy"
                className="social-media-icon"
              >
                <PicartoIcon></PicartoIcon>
              </a>
              <a
                href="https://www.furaffinity.net/user/quilyn/"
                className="social-media-icon"
              >
                <FuraffinityIcon></FuraffinityIcon>
              </a>
            </div>
            <div>Copyright © {new Date().getFullYear()} Feral Fantasty</div>
          </div>
        </div>
      </div>
    </Router>
  );
}

function Store() {
  const [product, setProduct] = React.useState("Product A");
  const [email, setEmail] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(false);

  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (emailIsValid(newEmail)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const handleSumbmit = (event) => {
    event.preventDefault();

    const data = {
      product: product,
      email: email,
    };

    fetch(
      "https://r48efykiy6.execute-api.us-east-2.amazonaws.com/default/preorder",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        mode: "no-cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        return response.status;
      })
      .then((_) => {
        alert("Thank you! Your pre-order was submitted!");
      })
      .catch((_) => {
        alert(
          "There was an error submitting your pre-order. Please try again later."
        );
      })
      .finally(() => {
        setProduct("Product A");
        setEmail("");
        setValidEmail(false);
      });
  };

  const getEmailHelpText = () => {
    return !validEmail && email.length > 0
      ? "Email is invalid."
      : "We'll never share your email.";
  };

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  return (
    <div>
      <section>
        <SectionTitle title="Store"></SectionTitle>
        <div className="card-container">
          <Card
            source="./img/toy.webp"
            title="Mei Lung - Eastern Water Dragoness"
            text="You'll soon realize your journey was well worth it to find the last dragon of her kind. Wrap yourself in the elegant eastern water dragoness herself. Snug at the entrance, her slender body makes for a tight fit enough to make her belly bulge with your girth. Her long, slender body is able to bend and twist with ease. Get under her tail for an exotic experience."
          ></Card>

            {/*<Card
            source="./img/tasty.webp"
            title="Product B"
            text={sampleText}
          ></Card>*/}
        </div>
      </section>
      <section>
        <SectionTitle title="Pre-Order Here"></SectionTitle>
      </section>
      <form
        noValidate
        className="order-form restrict-width"
        onSubmit={handleSumbmit}
      >
        <TextField
          id="select-product"
          select
          label="Product"
          helperText="Please select a product."
          className="combo-box"
          value={product}
          onChange={handleProductChange}
        >
          <MenuItem key="Product A" value="Product A">
            Product A
          </MenuItem>
            {/*<MenuItem key="Product B" value="Product B">
            Product B
          </MenuItem>*/}
        </TextField>
        <FormControl className="text-field">
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <Input
            value={email}
            id="my-input"
            aria-describedby="my-helper-text"
            onChange={handleEmailChange}
            error={!validEmail && email.length > 0}
          />
          <FormHelperText id="my-helper-text">
            {getEmailHelpText()}
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!validEmail}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

function Commissions() {
  return (
    <div>
      <section>
        <SectionTitle title="Commissions"></SectionTitle>
        <div className="card-container">
          <Card
            source="./img/model.webp"
            title="Low Polygon Model"
            text="$65"
          ></Card>
          <Card
            source="./img/floof.webp"
            title="Full Character Sculpt"
            text="$193"
          ></Card>
        </div>
        <div className="card-container">
          <Card
            source="./img/tasty.webp"
            title="Animation Ready Character"
            text="$523"
          ></Card>
          <Card
            source="./img/quilyn.webp"
            title="Feral Fantasy License"
            text="$593"
          ></Card>
        </div>
      </section>
      <section>
        <SectionTitle title="Quotes"></SectionTitle>
        <div className="card-container">
          <div className="content-box">
            <p>
              For commission requests and quotes, please email me at{" "}
              <a href="mailto:QuilynDragon@gmail.com">QuilynDragon@gmail.com</a>
              .
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

function Gallery() {
  const sources = [
    "Sisu Progress - with background",
    "sisu",
    "quilynshowingoff",
    "quilynXiclyn",
    "TheCrags",
    "quilynref",
    "toy",
    "welcome",
  ];

  const images = sources.map((source) => (
    <img src={"./img/" + source + ".webp"} alt={source} key={source}></img>
  ));

  return (
    <div>
      <section>
        <SectionTitle title="Gallery"></SectionTitle>
        <div className="gallery restrict-width">{images}</div>
      </section>
    </div>
  );
}

function Home() {
  return (
    <div>
      <Carousel
        navButtonsAlwaysInvisible
        indicatorContainerProps={{ className: "dots" }}
        indicatorProps={{ className: "dot" }}
        activeIndicatorProps={{ className: "active-dot" }}
        animation="slide"
        className="carousel"
      >
        <Item source="./img/welcome.webp"></Item>
        <Item source="./img/mei lung.webp"></Item>
        <Item source="./img/upcoming.webp"></Item>
      </Carousel>
      <section>
        <SectionTitle title="Characters"></SectionTitle>
        <div className="card-container">
          <Card
            source="./img/quilyn.webp"
            title="Quilyn"
            text="Quilyn is my dragon always getting into things he probably shouldn't. Animations and toys coming soon for him!"
          ></Card>
          <Card
              source="./img/sisu.webp"
                title="*FANART* Sisu 3D Model"
                text="I do not claim Sisu as my character and am not affiliated with the franchise. The content here is fanart only."></Card>
        </div>
      </section>
      <section>
        <SectionTitle title="Products"></SectionTitle>
        <div className="card-container">
          <Card
            source="./img/toy.webp"
            title="Mei Lung - Eastern Water Dragoness"
            text="You'll soon realize your journey was well worth it to find the last dragon of her kind. Wrap yourself in the elegant eastern water dragoness herself. Snug at the entrance, her slender body makes for a tight fit enough to make her belly bulge with your girth. Her long, slender body is able to bend and twist with ease. Get under her tail for an exotic experience."
          ></Card>
          <Card
            source="./img/model.webp"
            title="3D Model and Animations"
            text="I do custom 3D models and animations available for purchase. Terms and conditions apply. I do both sfw and nsfw."
          ></Card>
        </div>
      </section>
      <section>
        <SectionTitle title="About"></SectionTitle>
        <div className="card-container">
          <div className="content-box">
            <div className="text-box">
              <p>
                Hello, I'm Quilyn. I make 3D Animations for the Furry Community.
                You may purchase a customized 3D model of your character. You
                may also purchase a Feral Fantasy License from me to include
                your character into my animations and merchandise.
              </p>
            </div>
            <div className="donate-box">
              <p>
                Consider donating to me and helping me become a full-time
                artist.
              </p>
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="business"
                  value="quilyndragon@gmail.com"
                />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  type="image"
                  src={"./img/donate.webp"}
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                  id="donate-button"
                />
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function Card(props) {
  return (
    <div className="card">
      {props.source && <img src={props.source} alt={props.title} />}
      <div className="card-text-container">
        {props.title && <h2>{props.title}</h2>}
        {props.text && <p>{props.text}</p>}
        {props.children}
      </div>
    </div>
  );
}

function SectionTitle(props) {
  return (
    <div className="restrict-width">
      <div className="section-title">
        <div className="title-spacer"></div>
        <h1 className="title">{props.title}</h1>
        <div className="title-spacer"></div>
      </div>
    </div>
  );
}

function Item(props) {
  return (
    <div className="item">
      <img src={props.source} alt="" />
    </div>
  );
}

function TwitterIcon(props) {
  return (
    <svg viewBox="0 0 300.00006 244.18703">
      <g transform="translate(-539.17946,-568.85777)" id="layer1">
        <path
          style={{
            fillOpacity: "1",
            fillRule: "nonzero",
            stroke: "none",
          }}
          d="m 633.89823,812.04479 c 112.46038,0 173.95627,-93.16765 173.95627,-173.95625 0,-2.64628 -0.0539,-5.28062 -0.1726,-7.90305 11.93799,-8.63016 22.31446,-19.39999 30.49762,-31.65984 -10.95459,4.86937 -22.74358,8.14741 -35.11071,9.62551 12.62341,-7.56929 22.31446,-19.54304 26.88583,-33.81739 -11.81284,7.00307 -24.89517,12.09297 -38.82383,14.84055 -11.15723,-11.88436 -27.04079,-19.31655 -44.62892,-19.31655 -33.76374,0 -61.14426,27.38052 -61.14426,61.13233 0,4.79784 0.5364,9.46458 1.58538,13.94057 -50.81546,-2.55686 -95.87353,-26.88582 -126.02546,-63.87991 -5.25082,9.03545 -8.27852,19.53111 -8.27852,30.73006 0,21.21186 10.79366,39.93837 27.20766,50.89296 -10.03077,-0.30992 -19.45363,-3.06348 -27.69044,-7.64676 -0.009,0.25652 -0.009,0.50661 -0.009,0.78077 0,29.60957 21.07478,54.3319 49.0513,59.93435 -5.13757,1.40062 -10.54335,2.15158 -16.12196,2.15158 -3.93364,0 -7.76596,-0.38716 -11.49099,-1.1026 7.78383,24.2932 30.35457,41.97073 57.11525,42.46543 -20.92578,16.40207 -47.28712,26.17062 -75.93712,26.17062 -4.92898,0 -9.79834,-0.28036 -14.58427,-0.84634 27.05868,17.34379 59.18936,27.46396 93.72193,27.46396"
        />
      </g>
    </svg>
  );
}

function FuraffinityIcon(props) {
  return (
    <svg viewBox="0 0 32 32">
      <path d="M22.427 6.844l-0.344 2.656 3.245 0.958 0.042 2.865 2.974 0.057-0.073 3.005 2.891-0.188c0.005-1.010 0.068-6.724 0.839-9.354zM15.141 24.318c0.073-0.281 0-1.203 0-1.526l-0.063-1.948c-2.698-0.115-5.604 0.427-5.604 2.911 0 0.542 0.229 1.026 0.568 1.401h4.417c0.333-0.188 0.578-0.448 0.682-0.839zM27.188 17.422l0.068-2.995-2.938-0.057-0.047-3.229-3.37-1.151 0.453-3.146h-12.573c-5.094 0-8.781 4.339-8.781 9.089v9.224h5.49c-0.036-0.333-0.047-0.672-0.031-1.005 0.198-4.891 5.599-5.729 9.656-5.609v-1.406c-0.068-1.135-0.99-2.141-3.656-2.141-1.776 0-3.885 0.229-5.25 0.724l0.359-3.182c1.307-0.365 2.776-0.724 5.938-0.724 6.099 0 6.771 2.703 6.724 5.844l-0.031 7.5h3.307v-0.005l0.125 0.005c4.406 0 8.031-3.589 8.484-7.891z"></path>
    </svg>
  );
}

function PicartoIcon(props) {
  return (
    <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
      <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12c6.628 0 12-5.373 12-12S18.628 0 12 0zM7.08 4.182h2.781c.233 0 .42.21.42.47v14.696c0 .26-.187.47-.42.47h-2.78c-.233 0-.42-.21-.42-.47V4.652c0-.26.187-.47.42-.47zm4.664 0a.624.624 0 0 1 .326.091c.355.209 7.451 4.42 8.057 4.78a.604.604 0 0 1 0 1.039c-.436.264-7.558 4.495-8.074 4.789a.577.577 0 0 1-.873-.512v-1.812c0-1.712 2.962-2.201 3.398-2.465a.604.604 0 0 0 0-1.04c-.605-.36-3.398-.746-3.398-2.452V4.79c0-.334.251-.605.564-.61z" />
    </svg>
  );
}

export default App;
